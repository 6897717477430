.small-card__title {
  text-align: center;
  color: #3d778a;
}

.card-labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 2em;
}

.card-labels__flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.title--reSize {
  max-width: 200px;
}

