.block-courses {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
}

.small-card {
  border: 1px solid #dfdfdf;
  margin: 10px 5px;
  padding: 10px;
  transition: all .5s ease;
  min-height: 200px;
  position: relative;
}

@media screen and (max-width: 400px) {
  .small-card {
    width: 300px;
  }
}

@media screen and (min-width: 400px) {
  .small-card {
    width: 400px;
  }
}

.small-card:hover {
  box-shadow: 1px 1px 4px #8a8a8a;
  cursor: pointer;
}

.small-card__title {
  display: flex;
  text-align: center;
  color: #3d778a;
  font-size: 1.8em;
  padding-top: 5px;
}

.small-card__title::before,
.small-card__title::after {
  content: "";
  flex: 1 1;
  background: #61adc7;
  height: 2px;
  align-self: center;
  margin: auto 0.6em;
}

