@import "../../../css/_variable.scss";

.block-courses {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
}

.small-card {
    border: 1px solid rgb(223, 223, 223);
    margin: 10px 5px;
    padding: 10px;
    transition: all .5s ease;
    min-height: 200px;
    position: relative;
    @media screen and (max-width: 400px) {
        & {
            width: 300px;
        }
    }
    @media screen and (min-width: 400px) {
        & {
            width: 400px;
        }
    }
    &:hover {
        box-shadow: 1px 1px 4px rgb(138, 138, 138);
        cursor: pointer;
    }

    &__title {
        display: flex;
        text-align: center;
        color: $blue-dark;
        font-size: 1.8em;
        padding-top: 5px;

        &::before,
        &::after {
            content: "";
            flex: 1 1;
            background: $blue-hell;
            height: 2px;
            align-self: center;
            margin: auto 0.6em;
        }
    }
}