@import "_variable.scss";

@media screen and (max-width: 800px) {
    .footer {
        justify-content: space-around;
    }
}
@media screen and (min-width: 800px) {
    .mobile-only{
        display: none;
    }
}
.footer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    padding: 6px 0;

    &__bde,
    &__nav,
    &__contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.3vw;
        .mobile-only{
            margin-top: -10px;
        }
    }

    &__contact {
        p {
            text-align: center;
            margin-bottom: 0.4rem;
        }
        p:nth-of-type(2) {
            white-space: nowrap;
        }
        a {
            margin: 0;
        }
    }

    &__bde {
        .logo {
            border-radius: 100%;
            height: 10vh;
            width: auto;
            margin-bottom: 0;
        }
        p {
            margin-bottom: 5px;
        }
    }

    &__nav {
        @at-root {
            @media screen and (max-width: 800px) {
                .footer{
                    &__nav {
                        display: none;
                    }
                }
            }
        }

        .title {
            font-style: italic;
            font-size: 18px;
            transition: all .3s;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            list-style: none;
            margin: 0;

            li a {
                color: dimgray;
                transition: color .4s ease;

                &:hover {
                    color: black;
                    transition: color .4s ease
                }
            }

        }
    }
}
