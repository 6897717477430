@import "../../../css/_variable.scss";

.card-course {
    margin: 25px auto;
    padding: 15px 20px;
    max-width: 1000px;
    border: 1px solid rgb(223, 223, 223);
    box-shadow: 1px 1px 8px #555;
    transition: box-shadow 0.4s ease;

    @media screen and (max-width: 1050px) {
        & {
            margin: 25px 10px;
        }
    }
    
}

/*=============================================
=            Section course card            =
=============================================*/
#course-like {
    cursor: pointer;
    font-size: 28px;
    color: rgb(207, 207, 77);
    opacity: .9;
}

.course-header {
    margin: 5px 0 10px;
    display: flex;
    justify-content: space-around;
    @media screen and (max-width: 400px) {
        & {
            flex-direction: column;
        }
    }
    &__title {
        text-align: center;
        color: $blue-title;
    }

    &__button {
        border: 2px solid $blue-title;
        background-color: $bkgd-color;
        margin-left: 5px;
        margin-bottom:10px;
        padding: 0 5px 0 5px;
        font-style: 12px;
        transition: all .4s ease-in-out;

        &:hover {
            background-color: $blue-title;
            color: white;
        }
    }
}


.course-legend {
    position: relative;

    .description{
        padding-left: 5px;
        min-height: 40px;
        display: flex;
        align-items: center;
    }
}

.labels{
    padding: 5px;
    display: flex;
    justify-content: space-between;

    &__btn {
        padding: 2px 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 10px;
        border: 2px solid $blue-title;
        border-radius: 24px;
        background-color: $blue-dark;
        color: white;
        font-size: 15px;
        transition: all .5s ease;
        cursor: default !important;
    
        &:hover {
            background-color: $blue-title;
        }
    
        &--matiere {
            border: 2px solid $green-hell;
            background-color: $green-btn;
            margin-right: 10px;
            margin-top: 5px;
            margin-bottom: 5px;
            
            &:hover {
                background-color: $green-hell;
            }
        }
    }
}

/*=====  End of Section course card  ======*/


/*=============================================
=            Section        document           =
=============================================*/

.card-document__upload{
    border: 2px solid $blue-title;
    display: inline-block;
    margin-top: 12px;
    padding: 4px;
    font-style: 12px;
    transition: all .4s ease-in-out;
    &:hover {
        border: 2px solid grey;
        color: $blue-dark;
    }
}

.link-upload{
    display: inline-block;
    transition: all .5s ease;

    &:hover{
        transform: scale(1.05);
    }
}
.document--btn {
    padding: 2px 15px;
    margin-left: 30px;
    border-radius: 24px;
    border: 2px solid $green-hell;
    background-color: $green-hell;
    color: white;
    font-size: 15px;
    transition: all .5s ease;
    cursor: default !important;

    @media screen and (max-width: 1050px) {
        & {
            margin-left: 10px;
        }
    }
}


/*=====  End of Section     document     ======*/
