@import "_variable.scss";

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


body {
  font-size: 100%;
  color: $primary-color;
  background-color: $bkgd-color;
  font-family: 'Quicksand', sans-serif;
  font-weight: normal;
  font-style: normal;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Secular One', sans-serif;
}


/*=====  Flexbox -> footer sticks to the bottom of the page  ======*/

.main-container {
  flex: 1 1 auto;
  padding-bottom: 3vh;
}

/*----------    ----------*/


footer {
  background-color: $grey-footer;
  margin-top: 10px;
}

a,
a:hover,
a:focus {
    text-decoration: none;
    color: black;
}
