.card-course {
  margin: 25px auto;
  padding: 15px 20px;
  max-width: 1000px;
  border: 1px solid #dfdfdf;
  box-shadow: 1px 1px 8px #555;
  transition: box-shadow 0.4s ease;
}

@media screen and (max-width: 1050px) {
  .card-course {
    margin: 25px 10px;
  }
}

/*=============================================
=            Section course card            =
=============================================*/

#course-like {
  cursor: pointer;
  font-size: 28px;
  color: #cfcf4d;
  opacity: .9;
}

.course-header {
  margin: 5px 0 10px;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 400px) {
  .course-header {
    flex-direction: column;
  }
}

.course-header__title {
  text-align: center;
  color: #61adc7;
}

.course-header__button {
  border: 2px solid #61adc7;
  background-color: rgba(40, 53, 131, 0.05);
  margin-left: 5px;
  margin-bottom: 10px;
  padding: 0 5px 0 5px;
  font-style: 12px;
  transition: all .4s ease-in-out;
}

.course-header__button:hover {
  background-color: #61adc7;
  color: white;
}

.course-legend {
  position: relative;
}

.course-legend .description {
  padding-left: 5px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.labels {
  padding: 5px;
  display: flex;
  justify-content: space-between;
}

.labels__btn {
  padding: 2px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  border: 2px solid #61adc7;
  border-radius: 24px;
  background-color: #3d778a;
  color: white;
  font-size: 15px;
  transition: all .5s ease;
  cursor: default !important;
}

.labels__btn:hover {
  background-color: #61adc7;
}

.labels__btn--matiere {
  border: 2px solid #0e9684;
  background-color: #2c6868;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.labels__btn--matiere:hover {
  background-color: #0e9684;
}

/*=====  End of Section course card  ======*/

/*=============================================
=            Section        document           =
=============================================*/

.card-document__upload {
  border: 2px solid #61adc7;
  display: inline-block;
  margin-top: 12px;
  padding: 4px;
  font-style: 12px;
  transition: all .4s ease-in-out;
}

.card-document__upload:hover {
  border: 2px solid grey;
  color: #3d778a;
}

.link-upload {
  display: inline-block;
  transition: all .5s ease;
}

.link-upload:hover {
  transform: scale(1.05);
}

.document--btn {
  padding: 2px 15px;
  margin-left: 30px;
  border-radius: 24px;
  border: 2px solid #0e9684;
  background-color: #0e9684;
  color: white;
  font-size: 15px;
  transition: all .5s ease;
  cursor: default !important;
}

@media screen and (max-width: 1050px) {
  .document--btn {
    margin-left: 10px;
  }
}

/*=====  End of Section     document     ======*/

