@import "../../../css/_variable.scss";

.small-card__title{
    text-align: center;
    color: $blue-dark;
}

.card-labels{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 2em;

    &__flex{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}
.title--reSize{
    max-width: 200px;
}