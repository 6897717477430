@import "~bootstrap/scss/bootstrap";
@import "_base.scss";
@import "_variable.scss";
@import "footer.scss";

/*----------        All Mixins        ----------*/

@mixin underline-link($color-underline: black){
    text-decoration: none;
    position: relative;
    &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        left: 0;
        background-color: $color-underline;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
    }
    &:hover:before{
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

/*----------        end mixins          ----------*/

/*=============================================
=            Login Page & Register            =
=============================================*/

.login__btn {
    padding: 5px 15px;
    border: 2px solid $blue-dark;
    background-color: #fff;
    border-radius: 24px;
    color: dimgray;
    opacity: .6;
    cursor: pointer;
    transition: all .4s ease-in-out;

    &:hover,
    &:focus {
        opacity: 1;
        padding: 5px 30px;
        color: white;
        background-color: $blue-dark;
        border-color: $blue-hell
    }

}

/*=====  End of Section comment block  ======*/


/*=============================================
=               Section  Navbar                =
=============================================*/

.nav-item {
    #nav__logout {
        color: rgb(192, 8, 8);
        font-style: italic;
        transition: color .2s ease;

        &:hover,
        &:focus {
            color: rgb(236, 51, 51);
        }
    }
}

.navbar-brand {
    font-family: 'Secular One', sans-serif;
}

.navbar-dark .nav-item .link-login{
    color: #61adc7;
    transition: color .3s ease;
    &:hover{ 
        color: #b7edff;
    }
}

.link-underline {
    @include underline-link(white);
}

/*=====  End of Section Navbar          ======*/


/*=============================================
=      Section Courses (common css for all components)     =
=============================================*/

.courses-title {
    text-align: center;
    margin-top: 20px;
    color: $blue-dark;
}

.card-courses__btn {
    border: 3px $blue-hell solid;
    transition: all .3s ease;
    display: inline-block;
    position: absolute;
    bottom: 10px;

    &:hover {
        background-color: rgb(170, 194, 201);
    }
}

/*=====  End of Section Courses          ======*/



/*=============================================
=            Section homepage               =
=============================================*/

.link-course{
    display: inline-block;
    font-size: 18px;
    font-style: italic;
    transition: all .5s ease;
    &:hover{
        color: grey;
        transform: scale(1.05);
    }
}

.alert-homepage{
    margin-top: 30px;
    border: 2px solid #8d8894;
    box-shadow: 2px 2px 6px $grey-footer;
    border-radius: 5px;
    padding: 10px 20px;
    transition: all .5s ease;
    &:hover{
        box-shadow: 3px 3px 10px #89a3d4;
    }
    .title{
        margin: 5px 0 10px 30px;
        color: grey;
    }

    &__link{
        @include underline-link;
    }

    p{
        margin-bottom: 0;
    }
}

.strong{
    font-weight: bold;
}

/*=====  End of Section homepage        ======*/


/*=============================================
=            Section        Profile            =
=============================================*/

.table-mobile{
    overflow-x: auto;
}


/*=====  End of Section Profile          ======*/
